import React from 'react';
import classNames from 'classnames';

import { Box, Icon } from '~/ui';
import { IconSize } from '~/ui/components/Icon/IconSizes';
import { IconName } from '~/ui/components/Icon/IconNames';

interface DropDownMenuItemProps {
    menuItem?: {
        onClick?: () => void;
        index?: number;
        disabled?: boolean;
        iconName?: IconName;
        iconColor?: string;
        iconSize?: IconSize;
        text?: string;
        content?: React.ReactNode;
    };
    className?: string;
    'data-testid'?: string;
}

const DropDownMenuItem: React.FC<DropDownMenuItemProps> = ({
    menuItem = {},
    className,
    children,
    ...extra
}) => {
    const {
        onClick,
        index,
        disabled,
        iconName,
        iconColor,
        iconSize,
        text,
        content
    } = menuItem;
    return (
        <Box
            role="menuitem"
            className={classNames('dropdown-flex', 'dropdown-item', className, {
                disabled
            })}
            onClick={onClick}
            onKeyDown={onClick}
            tabIndex={index}
            disabled={disabled}
            data-testid={extra['data-testid'] || 'dropdownmenuitem'}
        >
            {children}
            {!children && iconName && (
                <Icon
                    className="dropdown-icon"
                    icon={iconName}
                    color={iconColor || 'black'}
                    size={iconSize || 'm'}
                    data-testid={
                        extra['data-testid']
                            ? `${extra['data-testid']}-icon`
                            : 'dropdownmenuitem-icon'
                    }
                />
            )}
            {!children && (text || content) && (
                <span
                    className="dropdown-text _ml-3"
                    data-testid={
                        extra['data-testid']
                            ? `${extra['data-testid']}-text`
                            : 'dropdownmenuitem-text'
                    }
                >
                    {text || content}
                </span>
            )}
        </Box>
    );
};

export default DropDownMenuItem;
