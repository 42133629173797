import React from 'react';
import PT from 'prop-types';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '~/ui';

import './LabelInput.css';

export const labelInputTestIdSuffix = 'input-element';

interface LabelInputProps extends React.ComponentPropsWithRef<'input'> {
    label?: React.ReactNode;
    isErrorState?: boolean;
    initialInputField?: boolean;
    viewPassword?: boolean;
    passwordField?: boolean;
    togglePassword?: React.MouseEventHandler<HTMLButtonElement>;
    buttonStyle?: string;
    'data-testid'?: string;
}

const LabelInput = ({
    id,
    label,
    placeholder,
    isErrorState = false,
    value,
    onChange,
    type = 'text',
    initialInputField = true,
    viewPassword = true,
    passwordField = false,
    togglePassword,
    onFocus = () => {},
    onBlur = () => {},
    className,
    disabled,
    buttonStyle,
    required,
    ...extra
}: LabelInputProps) => {
    let inputType;
    if (passwordField) {
        inputType = viewPassword ? 'text' : 'password';
    } else inputType = type;
    const { t } = useTranslation(['usersAndPermissions', 'common', 'error']);
    return (
        <label
            htmlFor={id}
            className={`_fd-column _w-100 ${className}`}
            data-testid={extra['data-testid'] || 'label-input'}
        >
            <FlexLayout flexDirection="row" sx={{ columnGap: '0.4rem' }}>
                {label && (
                    <>
                        <span
                            className={`labelinput-title ${
                                initialInputField
                                    ? ''
                                    : 'secondary-labelinput-title'
                            }`}
                        >
                            {label}
                        </span>
                        {required && (
                            <span className="label-input_asterisk">*</span>
                        )}
                    </>
                )}
            </FlexLayout>

            <input
                id={id}
                data-testid={`${
                    extra['data-testid'] || 'label-input'
                }-${labelInputTestIdSuffix}`}
                type={inputType}
                className={`${
                    isErrorState ? 'label-input--error' : ''
                } label-input ${passwordField ? 'label-input_password' : ''}`}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                autoComplete="new-password"
                disabled={disabled}
            />
            {passwordField ? (
                <button
                    type="button"
                    onClick={togglePassword}
                    className={buttonStyle}
                >
                    {viewPassword
                        ? t('common:hidePassword')
                        : t('common:showPassword')}
                </button>
            ) : (
                ''
            )}
        </label>
    );
};

export default LabelInput;

LabelInput.propTypes = {
    /** Label id */
    id: PT.string,
    /** Label title */
    label: PT.oneOfType([PT.string, PT.node]),
    /** Input placeholder */
    placeholder: PT.string,
    /** Input value */
    value: PT.string,
    /** Input onChange function */
    onChange: PT.func,
    /** Error state of input */
    isErrorState: PT.bool,
    /** Input is the top one in the form */
    initialInputField: PT.bool,
    /** Input is of type password */
    passwordField: PT.bool,
    /** Show password input */
    viewPassword: PT.bool,
    /** Show/hide password input */
    togglePassword: PT.func,
    /** Input onFocus function */
    onFocus: PT.func,
    /** Input onBlur function */
    onBlur: PT.func,
    /** Classname of label component */
    className: PT.string,
    /** Input disabled */
    disabled: PT.bool
};
