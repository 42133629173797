import { idUtils } from '~/utils/id-utils';
import constants from '~/utils/constants';

// ideally we would just use an environment variable
function _getEnvironment() {
    const { hostname } = window.location;
    const isProd =
        hostname.includes('enterprise.') ||
        hostname.includes('admin.wisesystems');
    if (isProd) {
        return constants.environments.PROD;
    }
    if (hostname.includes('uat.')) {
        return constants.environments.UAT;
    }
    if (hostname.includes('demo.')) {
        return constants.environments.DEMO;
    }
    if (hostname.includes('stage.')) {
        return constants.environments.STAGE;
    }
    if (hostname.includes('sandbox.')) {
        return constants.environments.SANDBOX;
    }
    return constants.environments.DEV;
}

function initialize(clients, clientId, userId) {
    const client = clients.find((currentClient) => {
        return currentClient.id === clientId;
    });
    const environment = _getEnvironment();
    pendo.initialize({
        visitor: {
            // Required if user is logged in
            id: idUtils.getCombinedId(environment, userId),
            timeZone: client.timezone,
            clientName: client.name
            // email:        // Recommended if using Pendo Feedback, or NPS Email
            // full_name:    // Recommended if using Pendo Feedback
            // role:         // Optional

            // You can add any additional visitor level key-values here,
            // as long as it's not one of the above reserved names.
        },
        account: {
            id: idUtils.getCombinedId(environment, client.id), // Required if using Pendo Feedback
            clientName: client.name
            // name:         // Optional
            // is_paying:    // Recommended if using Pendo Feedback
            // monthly_value:// Recommended if using Pendo Feedback
            // planLevel:    // Optional
            // planPrice:    // Optional
            // creationDate: // Optional
            // You can add any additional account level key-values here,
            // as long as it's not one of the above reserved names.
        }
    });
}

export const thirdPartyEventMonitor = {
    initialize
};
